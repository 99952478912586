<template>
    <article>
        <div class="mainView">
        <el-tabs v-model="activeName" style="font-size: 25px;">
            <el-tab-pane label="Information" name="first">

                <el-form
                    class="block-form"
                    :model="infoForm"
                    :rules="rules1"
                    ref="infoForm"
                    :label-width="formLabelWidth"
                    size="medium"
                    >
                    <el-row :gutter="10">
                        <el-col :span="12">
                        <el-form-item label="First Name:" prop="givenName_pinyin">
                            <el-input
                            :disabled="!showInfoSubmit"
                            v-model="infoForm.givenName_pinyin"
                            ></el-input>
                        </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="12">
                        <el-form-item label="Last Name:" prop="lastName_pinyin">
                            <el-input
                            :disabled="!showInfoSubmit"
                            v-model="infoForm.lastName_pinyin"
                            ></el-input>
                        </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="12">
                        <el-form-item label="Gender:" prop="gender">
                            <div v-if="showInfoSubmit" style="display:inline-block;width:100%;float: left;">
                                <el-radio-group v-model="infoForm.gender">
                                    <div style="width:100px;float: left;"><el-radio label="Male" /></div>
                                    <div style="width:100px;float: left;"><el-radio label="Female" /></div>
                                </el-radio-group>
                            </div>

                            <div v-if="!showInfoSubmit" style="display:inline-block;width:100%; color: #606266;">
                               {{infoForm.gender}}
                            </div>
                        </el-form-item>
                        </el-col>
                    </el-row>


                    <el-row :gutter="10">
                        <el-col :span="12">
                        <el-form-item label="Graduation Year:" prop="graduation">
                            <div style="display: inline-block;width:100%">
                                <el-select :disabled="!showInfoSubmit" class="w140" v-model="infoForm.graduation"
                                    placeholder="Please select the expected year of high school graduation" clearable>
                                    <el-option v-for="item in graduationOption" :key="item" :label="item" :value="item"></el-option>
                                </el-select>
                            </div>
                        </el-form-item>
                        </el-col>
                    </el-row>


                    <el-row :gutter="10">
                        <el-col :span="12">
                        <el-form-item label="School:" prop="school">

                            <div style="display:inline-block;width:100%;color: #606266;">
                               {{infoForm.school}}
                            </div>
                            <!-- <el-input
                            :disabled="!showInfoSubmit"
                            v-model="infoForm.school"
                            ></el-input> -->
                        </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="12">
                        <el-form-item label="Email:" prop="email">
                            
                            <div style="display:inline-block;width:100%;color: #606266;">
                               {{infoForm.email}}
                            </div>
                        </el-form-item>
                        </el-col>
                    </el-row>
                    
                    <!-- <el-row :gutter="10">
                        <el-col :span="12">
                        <el-form-item label="" prop=""> -->
                            
                            <div style="display:inline-block;width:100%;color: #606266;margin-bottom:20px;font-size:15px">
                              Please confirm your name and school, the information will be used for the certificate.
                            </div>
                        <!-- </el-form-item>
                        </el-col>
                    </el-row> -->


                    <div class="updateBtn">
                        <div v-if="!showInfoSubmit" class="btn-link"  @click="editInfo()">
                            Edit
                        </div>


                        <div v-if="showInfoSubmit" class="btn-link" type="primary" @click="changeInfo('infoForm')">
                            Submit
                        </div>
                    </div>
                </el-form>
            

            </el-tab-pane>
            <el-tab-pane label="My Pass" name="second">

                <div style="float: left;">
                    <el-table :data="passArr"  empty-text="No Data" size="medium" border class="passtable"
                    @current-change="handleCurrentChange">
                        <el-table-column label="EAE Pass"  align="left">
                            <template slot-scope="scope">
                                {{   scope.row.pass.name}}
                            </template>
                        </el-table-column>
                        <el-table-column label="Expiry Date" width="150" align="left">
                        
                            <template slot-scope="scope">
                                {{  scope.row.expired_value_str }}
                            </template>
                        </el-table-column>      	

                        <!-- <el-table-column width="200" label="Published" align="center">
                            <template slot-scope="scope">
                                {{ scope.row.date_str }}
                            </template>
                        </el-table-column>

                        <el-table-column width="200" label="Operations" align="center">
                            <template slot-scope="scope">
                                <el-button class="btn-link" @click="toCheck(scope.row)" type="primary" size="small">Check</el-button>
                                <el-button class="btn-link" @click="deleteBulletin(scope.row)" type="primary" size="small">Delete</el-button>

                            </template>
                        </el-table-column> -->
                    </el-table>

                    <div style="float: left;width: 40vw;height: 50vh;margin-left: 20px;background-color: #f5f5f5;overflow-y: auto;">

                        <div style="font-size: 15px;margin: 10px;line-height: 25px;">
                            {{ selectPass.short_intro }}
                        </div>


                        <div style="width: 100%;text-align: center;font-size: 15px;margin-top: 20px;">
                            Pass Verification Records
                        </div>

                        <div style="font-size: 15px;margin: 10px;margin-top: 20px;" v-for="app in selectApps">
                            {{ app.application_payment_date +' | ' + app.project.project_ename }}

                        </div>

                    </div>



                </div>
                
            </el-tab-pane>


          </el-tabs>
      </div>
    </article>
</template>

<script>

import {

    updateStudentInfo,  
    // changePassword,
    getStudentInfo,
    // getAdviserInfo,
    // updateAdviserInfo
    getStudentPurchasdPasses
  } from "../../api/index";
import Cookie from "js-cookie";

import {getUserId, getUserType} from "../../utils/store";

export default {
    data(){
        return{
            activeName:'first',
            canSelectEvents:[],
            pswForm: {},
            infoForm:{},
            adviserInfoForm:{},
            formLabelWidth: "140px",
            loginId: getUserId(),
            rules: {
                initial_password: [
                { required: true, message: 'The initial password cannot be empty', trigger: "change" },
                ],
                new_password: [
                { required: true, message: 'The new password cannot be empty', trigger: "change" },
                ],
                new_password_again: [
                { required: true, message: 'Please enter the new password again', trigger: "change" },
                ],
            },

            rules1: {
                givenName_pinyin: [
                { required: true, message: 'The first Name cannot be empty', trigger: "change" },
                ],
                lastName_pinyin: [
                { required: true, message: 'The last Name cannot be empty', trigger: "change" },
                ],
                gender: [
                { required: true, message: 'Please select gender', trigger: "change" },
                ],
                graduation: [
                { required: true, message: 'Please select graduation', trigger: "change" },
                ],
                // school:[
                // { required: true, message: 'The school cannot be empty', trigger: "change" },
                // ],
            },
            rules2: {
                name: [
                { required: true, message: 'The name cannot be empty', trigger: "change" },
                ],
                position: [
                { required: true, message: 'The Position cannot be empty', trigger: "change" },
                ],
                origanization_type: [
                { required: true, message: 'Please select Origanization Type', trigger: "change" },
                ],
                
            },
            graduationOption: [
          
                "2024",
                "2025",
                "2026",
                "2027"

            ],

            origanizationOptions: [
                'Secondary School',
                'Study Abroad Agency',
                'Admission Consultant/Firm',
                'Academic Enrichment Center',
                'Other'
            ],

            showInfoSubmit:false,
            passArr:[],
            selectPass:{},
            selectApps:[]

        }
    },

    created() {

        // this.getStudentInfo();
        
    },
    mounted(){
        this.getStudentInfo(); //获取学生信息

    },
    methods:{
        getStudentInfo() {

            // this.dataLoading=true;
            let param = {
                student_id: this.loginId,
            };

            this.infoForm = {}


            getStudentInfo(param).then((res) => {
                // this.dataLoading=false;

                console.log(123123,res)
                if (res.code == 0) {
                    let info = res.data.student_info

                    var getInfo = {}
                    getInfo.givenName_pinyin = info.student_givenName_pinyin
                    getInfo.lastName_pinyin = info.student_lastName_pinyin

                    if (info.student_gender == 'M'|| info.student_gender == 'male'|| info.student_gender == 'Male'|| info.student_gender == '男') {
                        getInfo.gender = 'Male'; 
					}
					if (info.student_gender == 'F'|| info.student_gender == 'female' || info.student_gender == 'Female' || info.student_gender == '女') {
						getInfo.gender= 'Female'; 
					}

                    getInfo.graduation = info.student_graduation
                    getInfo.email = info.student_email
                    getInfo.school = info.account_name

                    this.infoForm = getInfo
                } else {
                    this.$message.error(res.msg);
                }
            });


            getStudentPurchasdPasses(this.loginId).then((res) => {
                // this.dataLoading=false;

                console.log(123123,res)
                
                this.passArr = res.data
            });
            
      },

      getAdviserInfo(){
        

        this.dataLoading=true;
            let param = {
                adviser_id: this.loginId,
            };

            this.infoForm = {}

            getAdviserInfo(param).then((res) => {
                this.dataLoading=false;

                console.log(123123,res)
                if (res.code == 0) {
                    let info = res.data.adviser_info

                    var getInfo = {}
                    getInfo.name = info.contact_enName            
                    getInfo.origanization = info.account_name
                    getInfo.position = info.contact_position

                    getInfo.email = info.contact_email

                    this.adviserInfoForm = getInfo
                } else {
                    this.$message.error(res.msg);
                }
            });
      },

      editInfo(){
            this.showInfoSubmit = true
      },

      changeInfo(infoForm){
        let _this = this;
        this.$refs[infoForm].validate((valid) => {
            if (valid) {
                _this.loadDialog = true;
            
                let param = {
                    student_id: _this.loginId,
                    student_gender: _this.infoForm.gender,
                    student_lastName_pinyin: _this.infoForm.lastName_pinyin,
                    student_givenName_pinyin: _this.infoForm.givenName_pinyin,
                    student_graduation: _this.infoForm.graduation,
                    student_email: _this.infoForm.email,
                    student_edit_school: _this.infoForm.school,

                };
                updateStudentInfo(param).then((res) => {
                    this.loadDialog = false;

                    if (res.code == 0) {
                        _this.$message.success('Information Updated Successfully');
                        this.showInfoSubmit = false

                    } else {
                        this.$message.error(res.msg);
                    }
                });
            
            } else {
                return false;
            }
        });
      },

      changeAdviserInfo(adviserInfoForm){
        let _this = this;
        this.$refs[adviserInfoForm].validate((valid) => {
            if (valid) {
                _this.loadDialog = true;
            
                let param = {
                    adviser_id: _this.loginId,
                    contact_enName: _this.adviserInfoForm.name,
                    contact_position: _this.adviserInfoForm.position,

                };
                updateAdviserInfo(param).then((res) => {
                    this.loadDialog = false;

                    if (res.code == 0) {
                        _this.$message.success('Information Updated Successfully');
                        this.showInfoSubmit = false

                    } else {
                        this.$message.error(res.msg);
                    }
                });
            
            } else {
                return false;
            }
        });
      },

      changePassword(pswForm) {
        let _this = this;
        this.$refs[pswForm].validate((valid) => {
            if (valid) {
            _this.loadDialog = true;
            if (_this.pswForm.new_password != _this.pswForm.new_password_again) {
                this.$message.warning('The two password entries are inconsistent');
            } else {


                    let param = {
                        student_id: _this.loginId,
                        initial_password: _this.pswForm.initial_password,
                        new_password: _this.pswForm.new_password,
                        new_password_again: _this.pswForm.new_password_again,
                        };
                    changePassword(param).then((res) => {
                        this.loadDialog = false;
                        if (res.code == 0) {
                            _this.$message.success('Password changed successfully, please log in again');
                            Cookie.set("loginId",'');
                            _this.$router.push({ name: "login" });
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                
                
            }
            } else {
            return false;
            }
        });
      },


      handleCurrentChange(row){
        console.log(11111,row)
        this.selectPass = row.pass
        this.selectApps = row.applications
      }
    }
}
</script>

<style>
::v-deep .el-tabs_item{
    color: #204386;
}

.mainView{
    width: 100vw;
    margin-left: 10%;
    margin-top: 20px;
    height: 80vh;
    /* background-color: aqua; */
}
.itemStyle{
    width: 100%;
    height: 40px;
    margin-top: 10px;
    /* background-color: bisque; */
}
.titleStyle{
    width: 160px;
    text-align: right;
    color: #333;
    font-size: 18px;
    font-weight: 500;
    line-height: 40px;
    float: left;

}
.inputStyle{
    width: 300px;
    text-align: left;
    margin-left: 200px;
    height: 40px;
    /* background-color: aqua; */
}
.eventStyle{
    margin-left: 30px;
    margin-right: 30px;
    height: 50px;
    background-color: #ffb414;
    color: #204386;
    border:1px solid #204386;
    border-radius: 4px;
    text-align: center;
    line-height: 50px;
    font-size: 20px;
    font-weight: 500;
}
.btn-link{
    border: none;
    color: white;
    width: 80px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 15px;
    border-radius: 4px;
    background-color: #ffb414;
    cursor: pointer;
}
.passtable{
    width: 40vw;
    float: left;
}
</style>